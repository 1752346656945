import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/index'
// import 'lib-flexible'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
router.beforeEach((to, from, next) => {
  var path = to.path
  if (path.endsWith('/en') || path.endsWith('/zh') || path.endsWith('/es')) {
    if (typeof path === 'string') {
      var pathArr = path.split('/')
      if (pathArr instanceof Array && pathArr.length > 0) {
        var language = pathArr[pathArr.length - 1]
        var str = language === 'zh' ? 'zh_cn' : language
        console.log('=str==', str)
        localStorage.setItem('language', str || '')
        // localStorage.setItem('language', language === 'zh' ? 'zh_cn' : language)
      }
    }
    next()
  } else {
    next({
      path: `${to.path}/${i18n.locale}`,
      name: to.name,
      query: to.query,
      params: to.params
    })
  }
})
export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
