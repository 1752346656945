export default {
  navTab: {
    title: 'Dino Call Center Solution',
    tab1: 'Iniciar',
    tab2: 'QUIÉNES SOMOS',
    tab3: 'TRABAJA CON NOSOTROS',
    tab4: 'CONTÁCTANOS'
  },
  homeTop: {
    h3Title: 'Dino Call Center Solution',
    h3Title1: 'Solución de Recursos Humanos y Centro de Llamadas',
    desc: 'Expertos en atención al cliente, soporte técnico y recurso humano'
  },
  homeBottom: {
    title: 'Qué ofrecemos',
    menu1: 'EFICIENCIA',
    menuDesc1: 'Operamos con un modelo de gestión que implementa un proceso de mejora continua en su operación y nuestro personal, apoyado en la Gestión de Fuerza de Trabajo, Capacitación, Inteligencia de Negocios y el Centro de Comando.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.',
    menu3: 'PERSONAL EXPERTO',
    menuDesc3: 'Contamos con la atracción, reclutamiento y capacitación de personal calificado, así como un programa de cultura organizacional que enfocado al bienestar de nuestros colaboradores para promover su desarrollo profesional.',
    menu4: 'LA MEJOR SOLUCIÓN DE',
    menu4br: 'CONTACTO',
    menuDesc4: 'Analizamos y optimizamos tus procesos para ser más eficientes y ayudar a rentabilizar tu empresa'
  },
  bottom: {
    title: 'Dino Call Center Solution',
    email: 'razy@cashewtek.com',
    facebook: 'Facebook: Dino Call Center Solution',
    about: 'SOBRE NOSOTROS',
    joinUs: 'TRABAJAR CON NOSOTROS',
    contact: 'CONTACTAR NOSOTROS',
    privacyTitle: 'Enlaces rápidos',
    privacy: 'Aviso de Privacidad'
  },
  aboutUs: {
    title: 'Perfil de la empresa',
    p1: 'Dino Call Centers, establecido en 2017,es un proveedor de soluciones de recursos humanos de renombre mundial, con sede en Washington, EE. UU.. Actualmente, las sucursales están ubicadas en Johor Bahru, Malasia, Yakartade Indonesia, Singapur, Delhi de India, México, Nigeria, Kenya, Tanzania.',
    p2: 'Actualmente, la oficina mexicana se conecta principalmente con las empresas financieras Soform sobre sus operaciones como los recordatorios de reembolso de préstamos bancarios y  de centros de atención al cliente. La Oficina en México & Venezuela cuenta con 300 empleados (se espera a construir una oficina con 500 empleados), y el negocio del mercado mexicano es actualmente la sección de negocios con alto potencial de crecimiento  en la que se enfoca el grupo.',
    p3: 'Con el crecimiento económico rápido en México, tantas las empresas locales como las multinacionales, han logrado el desarrollo a gran velocidad en sus negocios.Además,sus demandas de servicio al cliente se han vuelto cada vez más fuertes. Así los recursos humanos de atención al cliente se han convertido en un bloqueo creativo que restringe la rápidez del desarrollo de las empresas. Estamos comprometidos a proporcionar a los cliente los servicios de alta calidad,como la consultoría de preparación de soporte al cliente,la consultoría de reclutamiento / capacitación / evaluación de persona de soporte al cliente y, servicios de subcontratación del centro de servicio al cliente y,ayudamos a las empresas a resolver los problemas complicados del centro de servicio al cliente.',
    p4: 'Para los empleados',
    p5: 'Excelentemente, ofrecemos salarios competitivos y la oportunidad de trabajar y estudiar en la sede del Grupo Americano. Respetando plenamente las políticas, las leyes y los costumbres de Mexico & Venezuela, hemos contratado a un bufete profesional para facilitar a construir un sistema legal de operación de la empresa que cumpla con las normas, a realizar una formación jurídica en cumplimiento empresarial, confidencialidad de datos de la empresa, relaciones laborales, y capacitación de firmar los documentos legales correspondientes.'
  },
  joinUs: {
    title: 'Reclutamiento',
    subTitle: 'Asesor telefónico',
    descTitle: 'Descripción de puesto',
    descSubTitle: '【Asesor telefónico】',
    content1: 'Por expansión IMPORTANTE EMPRESA DEL RAMO FINTECH se en-',
    content2: 'invitamos a unirte a nuestro gran equipo de asesores telefónicos!!',
    section1Title: 'Ofrecemos:',
    section1Desc1: 'Sueldo base MEX 5500',
    section1Desc2: 'Incentivos por productividad desde los MEX 1000',
    section1Desc3: 'Bono de puntualidad MEX 500',
    section1Desc4: 'Bono por referidos MEX 350',
    section1Desc5: 'Prestaciones de ley desde el primer día',
    section1Desc6: 'Capacitación pagada',
    section1Desc7: 'Servicio de Coffe Break gratuito',
    section1Desc8: 'Comedor con máquina de refrescos (Terraza con vista al castillo de Chapultepec)',
    section1Desc9: 'Salas de Juegos',
    section1Desc10: 'Instalaciones modernas',
    section2Title: 'Requisitos:',
    section2Desc1: 'De 18 a 45 años',
    section2Desc2: 'Secundaria concluida',
    section2Desc3: 'Documentación en regla',
    section2Desc4: 'No se necesitas Experiencia ¡¡Nosotros te capacitamos!!',
    section2Desc5: 'Turno de 9 a 6 de lunes a domingo con descanso entre semana',
    vitae: 'Manera de entregar (postular) su Curriculum Vitae:',
    vitaeGray: 'Postulación vía Correo electrónico',
    vitaeOrange: '“razy@cashewtek.com”'
  },
  contactUs: {
    title: 'Contáctanos',
    p1: 'Direccion de empresa：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'de México, CDMX',
    p4: 'Correo Electronico：',
    p5: 'razy@cashewtek.com',
    p6: 'Facebook：',
    p7: 'Dino Call Center Solutions'
  }
}
