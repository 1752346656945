import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'home'
    },
    redirect: '/home',
    component: resolve => {
      require(['@/views/home/index.vue'], resolve)
    }
  },
  {
    path: '/home/:lang',
    name: 'Home1',
    meta: {
      title: 'home',
      hidden: true
    },
    component: resolve => {
      require(['@/views/home/index.vue'], resolve)
    }
  },
  {
    path: '/about_us/:lang',
    name: 'AboutUs',
    meta: {
      title: '关于我们'
    },
    component: resolve => {
      require(['@/views/aboutUs/index.vue'], resolve)
    }
  },
  {
    path: '/join_us/:lang',
    name: 'joinUs',
    meta: {
      title: '人才招聘'
    },
    component: resolve => {
      require(['@/views/joinUs/index.vue'], resolve)
    }
  },
  {
    path: '/contact_us/:lang',
    name: 'ContactUs',
    meta: {
      title: '联系我们'
    },
    component: resolve => {
      require(['@/views/contactUs/index.vue'], resolve)
    }
  }
  // {
  //   path: '/privacy_statement/:lang',
  //   name: 'PrivacyStatement',
  //   meta: {
  //     title: '隐私声明'
  //   },
  //   component: resolve => {
  //     require(['@/views/privacy/index.vue'], resolve)
  //   }
  // }
]
const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
